<template>
    <v-container class="page_not_found">
        <v-row class="justify-center">
            <v-col lg="6" sm="12" cols="12" md="6">
                 <v-img :src="page_not_found" max-height="600" contain max-width="500" class="ma-auto"></v-img>
            </v-col>
            <v-col lg="6" sm="12" cols="12" md="6" class="text-center">
                <h4>Internal Server Error</h4>
                <p>The server has been deserted for a while.Please be patient or try again later.</p>
                <v-btn class="warning rounded-pill pr-10 pl-10 font-weight-bold text-h5 btn_text" medium link href="/">Home</v-btn>
            </v-col>
            
        </v-row>
    </v-container>
    </template>
    <script>
    
    export default{
    
        data:()=>({
          page_not_found:require('@/assets/images/500.svg')
        })
    }
    
    </script>
    <style scoped>
    .page_not_found{
        padding-top: 10%;
    }
    .page_not_found h4{
        font-family: 'Nunito' !important;
        padding-top: 6%;
        margin-left: 0% !important;
        font-weight: 700 !important;
        font-size: 5.0rem !important;
    }
    .btn_text{
        font-family: 'Nunito' !important;
        font-size: 1.0rem !important;
    }
 
    
    </style>